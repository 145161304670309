<template>
  <v-container
    id="locations"
    fluid
    tag="section"
  >
    <base-material-tabs
      v-model="mainTab"
      background-color="transparent"
      centered
      icons-and-text
      show-arrows
    >
      <v-tab
        v-for="(tab, i) in mainTabs"
        :key="i"
      >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>

      <v-tabs-items
        v-model="mainTab"
        class="transparent"
      >
        <v-tab-item>
          <locations />
        </v-tab-item>
      </v-tabs-items>
    </base-material-tabs>
  </v-container>
</template>

<script>
  export default {
    components: {
      Locations: () => import('./Locations'),
    },
    data: () => ({
      mainTab: 0,
      mainTabs: [
        { text: 'Locations', icon: 'mdi-map-marker' },
      ],
    }),
  }
</script>
